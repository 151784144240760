import 'regenerator-runtime/runtime';
import axios from 'axios';
const BASE_URL = process.env.NODE_ENV === 'development' ? 'https://reviewourchurch.dev/api' : 'https://reviewourchurch.com/api';
import Swiper, { SwiperOptions } from 'swiper/swiper-bundle.min.js';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import stylescss from "bundle-text:./styles.scss";
/*import './preview-scripts';*/
if(window.roc_loaded === true){
  return;
}else{
  window.roc_loaded = true;
}
const div_element = document.getElementById('roc-reviews');
let slider, masonry, widget_type, floating_rotation_interval = null;
let reviews = null;
const is_local = window.location.origin === 'null';
async function setupPreviewEvents(div){
  div.addEventListener('settings-changed',async (e) => {
    console.log(e);
    const url = `${BASE_URL}/get-widget/${e.detail.settings.uuid}`;
    await getWidget(url,e.detail.settings).then((resp_settings) => {
      reviews = resp_settings.reviews;
      if(floating_rotation_interval !== null){
        clearInterval(floating_rotation_interval);
      }
      buildWidget(e.target, reviews, e.detail.settings.type, e.detail.settings);
    });
  });
}
const getReviews = async (unique_id) => {
  try {
    const response = await axios.get(`${BASE_URL}/reviews/${unique_id}`);

    return  response.data;
  } catch (errors) {
    console.error(errors);
  }
};
export const getWidget = async (url,settings = null) => {
  try {
    const setting_response = await axios.post(url,settings);
    return setting_response.data;
  } catch (errors) {
    console.error(errors);
  }
};
const createReviewElement = (item,settings) => {
  let thisElement;
  const reviewElement = document.createElement('div');
  const reviewContainerElement = document.createElement('div');
  let img_element,star_element,date_element,name_element, text_element;
  if(settings.review_style.show_author_picture){
    img_element = document.createElement('img');
    img_element.setAttribute('class','roc-review-img');
    img_element.src = item.author_image;
  }
  if(settings.review_style.show_author_name){
    name_element = document.createElement('div');
    name_element.setAttribute('class','roc-review-name');
    name_element.textContent = item.author_title;
  }
  if(settings.review_style.show_rating){
    star_element = document.createElement('div');
    star_element.setAttribute('class','roc-review-rating');
    const rating_width = (item.review_rating / 5) * 100;
    star_element.innerHTML = `<div class="rating-upper" style="width: ${rating_width}%; color: ${settings.appearance_rating.color}"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div> <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>`;
  }
  if(settings.review_style.show_date){
    date_element = document.createElement('div');
    date_element.setAttribute('class','roc-review-date');
    date_element.textContent = item.days_ago;
  }
  if(item.review_text !== null) {
    text_element = document.createElement('div');
    text_element.setAttribute('class', 'roc-review-text');
    text_element.setAttribute('style',`font-size:${settings.appearance_review.font_size}px`);
    const random_id = uuidv4();
    if (settings.type !== 'masonry' && settings.review_style.show_read_more_button) {
      text_element.innerHTML = `<input type="checkbox" id="roc-text-${random_id}"><p>${item.review_text}</p> <label class="roc-text-label" for="roc-text-${random_id}" role="button" />`;
    } else {
      text_element.innerHTML = `<p>${item.review_text}</p>`;
    }
    setupReadMore(settings,text_element);
  }
  switch(settings.review_style.style){
    case "comment":
      if(settings.type === 'slider' || settings.type === 'masonry'){
        thisElement = reviewContainerElement;
      }else{
        thisElement = reviewElement;
      }
      thisElement.setAttribute('class','roc-rev-comment');
      thisElement.setAttribute('style',`color:${settings.appearance_review.text_color};`);
      const reviewTopElement = document.createElement('div');
      reviewTopElement.setAttribute('class','roc-comment-top');
      reviewTopElement.setAttribute('style',`border-radius:${settings.appearance_review.border_radius}px; background-color:${settings.appearance_review.background_color};`);
      const reviewBottomElement = document.createElement('div');
      reviewBottomElement.setAttribute('class','roc-comment-bottom');
      if(settings.review_style.show_rating) {
        reviewTopElement.appendChild(star_element);
      }
      if(item.review_text !== null) {
        reviewTopElement.appendChild(text_element);
      }
      if(settings.review_style.show_source){
        const posted_on_google_element = document.createElement('div');
        posted_on_google_element.setAttribute('class','roc-posted-on-source');
        posted_on_google_element.innerHTML = `<div class="roc-posted-on">Posted on</div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="64" height="22"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg>`;
        if(settings.settings.enable_external_links){
          const google_link = document.createElement('a');
          google_link.setAttribute('class','roc-review-link')
          if(settings.settings.open_links_in_new_window){
            google_link.setAttribute('target','_blank');
          }
          google_link.setAttribute('href',item.review_link);
          google_link.appendChild(posted_on_google_element);
          reviewTopElement.appendChild(google_link);
        }else {
          reviewTopElement.appendChild(posted_on_google_element);
        }
      }
      const reviewBottomElementInner = document.createElement('div');
      const reviewBottomElementInnerRight = document.createElement('div');
      reviewBottomElementInnerRight.setAttribute('class','roc-comment-bottom-right');
      if(settings.review_style.show_author_picture) {
        reviewBottomElementInner.appendChild(img_element);
      }
      if(settings.review_style.show_author_name) {
        reviewBottomElementInnerRight.appendChild(name_element);
      }
      if(settings.review_style.show_date) {
        reviewBottomElementInnerRight.appendChild(date_element);
      }
      reviewBottomElementInner.appendChild(reviewBottomElementInnerRight);
      reviewBottomElement.appendChild(reviewBottomElementInner);
      thisElement.appendChild(reviewTopElement);
      if(settings.settings.enable_external_links){
        const google_user_link = document.createElement('a');
        google_user_link.setAttribute('class','roc-review-link')
        if(settings.settings.open_links_in_new_window){
          google_user_link.setAttribute('target','_blank');
        }
        google_user_link.setAttribute('href',item.review_link);
        google_user_link.appendChild(reviewBottomElement);
        thisElement.appendChild(google_user_link);
      }else {
        thisElement.appendChild(reviewBottomElement);
      }

      if(thisElement !== reviewElement){
        reviewElement.appendChild(thisElement);
      }
      break;
    case "compact":
      if(settings.type === 'slider' || settings.type === 'masonry'){
        thisElement = reviewContainerElement;
      }else{
        thisElement = reviewElement;
      }
      thisElement.setAttribute('class','roc-rev-compact');
      thisElement.setAttribute('style',`border-radius:${settings.appearance_review.border_radius}px; background-color:${settings.appearance_review.background_color}; color:${settings.appearance_review.text_color};`);
      const reviewCompactTopElement = document.createElement('div');
      reviewCompactTopElement.setAttribute('class','roc-compact-top');
      const reviewCompactTopLeftElement = document.createElement('div');
      const reviewCompactTopRightElement = document.createElement('div');
      reviewCompactTopRightElement.setAttribute('class','roc-compact-top-right')
      if(settings.review_style.show_author_picture) {
        reviewCompactTopLeftElement.appendChild(img_element);
      }
      if(settings.review_style.show_author_name) {
        reviewCompactTopRightElement.appendChild(name_element);
      }
      reviewCompactTopElement.appendChild(reviewCompactTopLeftElement);
      const reviewCompactTopLowerElement = document.createElement('div');
      reviewCompactTopLowerElement.setAttribute('class','roc-compact-lower-right');
      if(settings.review_style.show_rating) {
        reviewCompactTopLowerElement.appendChild(star_element)
      }
      if(settings.review_style.show_date) {
        reviewCompactTopLowerElement.appendChild(date_element);
      }
      reviewCompactTopRightElement.appendChild(reviewCompactTopLowerElement);
      reviewCompactTopElement.appendChild(reviewCompactTopRightElement);
      if(settings.settings.enable_external_links){
        const google_user_link = document.createElement('a');
        google_user_link.setAttribute('class','roc-review-link')
        if(settings.settings.open_links_in_new_window){
          google_user_link.setAttribute('target','_blank');
        }
        google_user_link.setAttribute('href',item.review_link);
        google_user_link.appendChild(reviewCompactTopElement);
        thisElement.appendChild(google_user_link);
      }else {
        thisElement.appendChild(reviewCompactTopElement);
      }
      if(item.review_text !== null) {
        thisElement.appendChild(text_element);
      }
      if(settings.review_style.show_source){
        const posted_on_google_element = document.createElement('div');
        posted_on_google_element.setAttribute('class','roc-posted-on-source');
        posted_on_google_element.innerHTML = `<div class="roc-posted-on">Posted on</div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="64" height="22"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg>`;
        if(settings.settings.enable_external_links){
          const google_link = document.createElement('a');
          google_link.setAttribute('class','roc-review-link')
          if(settings.settings.open_links_in_new_window){
            google_link.setAttribute('target','_blank');
          }
          google_link.setAttribute('href',item.review_link);
          google_link.appendChild(posted_on_google_element);
          thisElement.appendChild(google_link);
        }else {
          thisElement.appendChild(posted_on_google_element);
        }
      }
      if(thisElement !== reviewElement){
        reviewElement.appendChild(thisElement);
      }
      break;
    case "centered":
      if(settings.type === 'masonry' || settings.type === 'slider'){
        thisElement = reviewContainerElement;
      }else{
        thisElement = reviewElement;
      }
      thisElement.setAttribute('class','roc-rev-centered');
      thisElement.setAttribute('style',`border-radius:${settings.appearance_review.border_radius}px; background-color:${settings.appearance_review.background_color}; color:${settings.appearance_review.text_color};`);
      if(settings.settings.enable_external_links){
        const google_user_link = document.createElement('a');
        google_user_link.setAttribute('class','roc-review-link')
        if(settings.settings.open_links_in_new_window){
          google_user_link.setAttribute('target','_blank');
        }
        google_user_link.setAttribute('href',item.review_link);
        if(settings.review_style.show_author_picture) {
          google_user_link.appendChild(img_element);
        }
        if(settings.review_style.show_author_name) {
          google_user_link.appendChild(name_element);
        }
        thisElement.appendChild(google_user_link);
      }else {
        if(settings.review_style.show_author_picture) {
          thisElement.appendChild(img_element);
        }
        if(settings.review_style.show_author_name) {
          thisElement.appendChild(name_element);
        }
      }
      if(settings.review_style.show_rating) {
        thisElement.appendChild(star_element);
      }
      if(settings.review_style.show_date) {
        thisElement.appendChild(date_element);
      }
      if(item.review_text !== null) {
        thisElement.appendChild(text_element);
      }
      if(settings.review_style.show_source){
        const posted_on_google_element = document.createElement('div');
        posted_on_google_element.setAttribute('class','roc-posted-on-source');
        posted_on_google_element.innerHTML = `<div class="roc-posted-on">Posted on</div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="64" height="22"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg>`;
        if(settings.settings.enable_external_links){
          const google_link = document.createElement('a');
          google_link.setAttribute('class','roc-review-link')
          if(settings.settings.open_links_in_new_window){
            google_link.setAttribute('target','_blank');
          }
          google_link.setAttribute('href',item.review_link);
          google_link.appendChild(posted_on_google_element);
          thisElement.appendChild(google_link);
        }else {
          thisElement.appendChild(posted_on_google_element);
        }
      }
      if(thisElement !== reviewElement){
        reviewElement.appendChild(thisElement);
      }
      break;
  }
  return reviewElement;
};
const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};
const buildWidgetInner = (this_element, reviewItems, type, settings, append = false) => {
  switch(type){
    case 'grid':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          this_element.appendChild(createReviewElement(reviewItem, settings));
        });
      }
      setupReadMore(settings,this_element);
      break;
    case 'slider':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          this_element.appendChild(createReviewElement(reviewItem, settings)).setAttribute('class','swiper-slide');
        });
      }
      break;
    case 'masonry':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          const grid_element = createReviewElement(reviewItem, settings);
          grid_element.setAttribute('class','grid-item');
          //
          grid_element.setAttribute('style',`width:${100/settings.layout_masonry.columns}%; padding:${settings.layout_masonry.gap}px;`);
          this_element.appendChild(grid_element);
          if(append){
           masonry.appended(grid_element);
          }
        });
      }
      break;
    case 'list':
    case 'floating':
    case 'floating_rotator':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          this_element.appendChild(createReviewElement(reviewItem, settings));
        });
      }
      break;
  }
  return this_element;
};
export const buildWidget = (div, paginatedReviewItems, layout_type, settings) => {
  const reviewItems = paginatedReviewItems.data;
  if(slider !== null && typeof slider === 'object'){
    slider.destroy();
  }
  if(masonry !== null && typeof masonry === 'object'){
    masonry.destroy();
    masonry = null;
  }
  if(settings.settings.font.font_family && settings.settings.font.font_family !== 'system'){
    div.setAttribute('style',`font-family:${settings.settings.font.font_family};`);
  }else{
    div.setAttribute('style','font-family:revert;');
  }

  div.innerHTML = '';
  if(!settings.full_width && settings.defined_width && settings.type !== 'floating'){
    div.setAttribute('style',`width:100%; max-width:${settings.defined_width}px; margin:0 auto;`)
  }
  const title_element = document.createElement('div');
  if(settings.include_title){
    title_element.setAttribute('class','roc-title');
    title_element.setAttribute('style',`color:${settings.appearance_title.title_color}; font-size:${settings.appearance_title.title_font_size}px;`)
    title_element.innerHTML = settings.title;
    if(!settings.type.includes('floating')) {
      div.appendChild(title_element);
    }
  }
  const caption_element = document.createElement('div');
  if(settings.caption && settings.caption !== ""){
    caption_element.setAttribute('class','roc-caption');
    caption_element.setAttribute('style',`color:${settings.appearance_title.caption_color}; font-size:${settings.appearance_title.caption_font_size}px;`)
    caption_element.innerHTML = settings.caption;
    if(!settings.type.includes('floating')) {
      div.appendChild(caption_element);
    }
  }
  let inner_widget;
  switch(layout_type){
    case 'grid':
      const grid_element = document.createElement('div');
      grid_element.setAttribute('class','roc-grid')
      grid_element.setAttribute('style',`grid-template-columns: repeat(${settings.layout_grid.columns}, minmax(0px, 1fr)); gap: ${settings.layout_grid.gap}px;`)
      inner_widget = buildWidgetInner(grid_element, reviewItems, layout_type, settings);
      div.appendChild(inner_widget);
      break;
    case 'slider':
      const swiper_element = document.createElement('div');
      swiper_element.setAttribute('class','swiper roc-contain-'+layout_type);

      const swiper_wrapper_element = document.createElement('div');
      swiper_wrapper_element.setAttribute('class','swiper-wrapper');
      inner_widget = buildWidgetInner(swiper_wrapper_element, reviewItems, layout_type, settings);
      swiper_element.appendChild(inner_widget);
      if(settings.layout_slider.enable_arrows) {
        const left_button = document.createElement('div');
        left_button.setAttribute('class', 'swiper-button-prev');
        left_button.setAttribute('style',`color:${settings.appearance_navigation.arrow_color}`)
        swiper_element.appendChild(left_button);

        const right_button = document.createElement('div');
        right_button.setAttribute('class', 'swiper-button-next');
        right_button.setAttribute('style',`color:${settings.appearance_navigation.arrow_color}`)
        swiper_element.appendChild(right_button);
      }
      div.appendChild(swiper_element);
      slider = new Swiper('.swiper', {
        autoplay: !settings.layout_slider.autorotation ? false : {
          delay: settings.layout_slider.autorotate_delay * 1000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable:true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slideNextClass:'.roc-slider-button-next',
        slidesPerView: 1,
        spaceBetween: settings.layout_slider?.gap,
        breakpoints:{
          640:{
            slidesPerView:2
          },
          860:{
            slidesPerView: settings.layout_slider?.columns,
          }
        }
      });
      break;
    case 'masonry':
      const masonry_element = document.createElement('div');
      masonry_element.setAttribute('class','roc-masonry-grid')
      inner_widget = buildWidgetInner(masonry_element, reviewItems, layout_type, settings);
      div.appendChild(inner_widget);
      masonry = new Masonry(masonry_element, {
        // options
        itemSelector: '.grid-item',
        horizontalOrder: false,
        percentPosition: true,
      });
      break;
    case 'list':
      const list_element = document.createElement('div');
      list_element.setAttribute('class','roc-list')
      list_element.setAttribute('style',`grid-template-columns: 1fr; gap: ${settings.layout_list.gap}px;`)
      inner_widget = buildWidgetInner(list_element, reviewItems, layout_type, settings);
      div.appendChild(inner_widget);
      break;
    case 'floating_rotator':
      if(floating_rotation_interval !== null){
        clearInterval(floating_rotation_interval);
      }
      if(is_local){
        const preview_button = document.createElement('button');
        preview_button.classList.add('floating-preview');
        preview_button.setAttribute('type','button');
        preview_button.innerText = "Preview";
        div.appendChild(preview_button);
        preview_button.addEventListener('click', function (event) {
          if(event.target.innerText === 'Preview'){
            event.target.innerText = 'Stop Preview';
            document.getElementById('roc-floating-rotator').classList.remove('is-local');
            //document.getElementById('roc-floating-rotator').classList.remove('visible');
            floatingCounter = 0;
            floating_rotation_interval = setInterval(function () {
              if (floatingCounter >= reviews.data.length) {
                floatingCounter = 0;
              }
              setupFloatingData(reviews.data[floatingCounter], settings);
            }, (settings.layout_floating_rotator.delay * 1000) + 3000);
          }else{
            if(floating_rotation_interval !== null){
              clearInterval(floating_rotation_interval);
            }
            event.target.innerText = 'Preview';
            document.getElementById('roc-floating-rotator').classList.add('is-local');
            document.getElementById('roc-floating-rotator').classList.add('visible');
          }
        });
      }
      /*div.setAttribute('style','position:fixed; bottom:10px;');*/
      const floating_rotator = document.createElement('div');
      floating_rotator.setAttribute('id','roc-floating-rotator');
      floating_rotator.setAttribute('style',`border-radius:${settings.appearance_review.border_radius}px; background-color:${settings.appearance_review.background_color}; ${settings.layout_floating_rotator.position}:10px`)
      if(is_local){
        floating_rotator.classList.add('is-local');
      }
      if(settings.review_style.show_author_picture){
        const img_element = document.createElement('img');
        img_element.setAttribute('class','roc-review-img');
        img_element.setAttribute('id','roc-floating-image');
        floating_rotator.appendChild(img_element);
      }
      const floating_main = document.createElement('div');
      floating_main.setAttribute('class','roc-floating-main');
      if(settings.review_style.show_author_name) {
        const name_element = document.createElement('div');
        name_element.setAttribute('class','roc-review-name');
        name_element.setAttribute('id','roc-floating-name');
        name_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
        floating_main.appendChild(name_element);
      }
      const text_element = document.createElement('div');
      text_element.setAttribute('class', 'roc-review-text');
      text_element.setAttribute('style',`font-size:${settings.appearance_review.font_size}px`);
      text_element.setAttribute('id', 'roc-floating-text');
      text_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
      floating_main.appendChild(text_element);
      const floating_main_footer = document.createElement('div');
      floating_main_footer.setAttribute('class','roc-floating-main-footer');
      if(settings.review_style.show_source){
        const posted_on_google_element = document.createElement('div');
        posted_on_google_element.setAttribute('class','roc-posted-on-source');
        posted_on_google_element.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="64" height="22"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg>`
        floating_main_footer.appendChild(posted_on_google_element);
      }
      if(settings.review_style.show_rating) {
        const star_element = document.createElement('div');
        star_element.setAttribute('class','roc-review-rating');
        star_element.innerHTML = `<div class="rating-upper" id="roc-floating-rating"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div> <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>`;
        floating_main_footer.appendChild(star_element);
      }
      if(settings.review_style.show_date) {
        const date_element = document.createElement('div');
        date_element.setAttribute('class','roc-review-date');
        date_element.setAttribute('id','roc-floating-date');
        date_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
        floating_main_footer.appendChild(date_element);
      }
      floating_main.appendChild(floating_main_footer);
      const floating_close_button = document.createElement('button');
      floating_close_button.setAttribute('class','roc-floating-close roc-sess-close');
      floating_close_button.setAttribute('type','button');
      floating_close_button.innerHTML = `<svg style="width:15px;height:15px; color:${settings.appearance_review.text_color}" viewBox="0 0 24 24" class="roc-sess-close"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" class="roc-sess-close" /></svg>`;
      if(settings.settings.enable_external_links){
        const link = document.createElement('a');
        link.setAttribute('id','roc-floating-link')
        if(settings.settings.open_links_in_new_window){
          link.setAttribute('target','_blank');
        }
        link.appendChild(floating_main);
        floating_rotator.appendChild(link);
      }else{
        floating_rotator.appendChild(floating_main);
      }
      floating_rotator.appendChild(floating_close_button);
      if(!sessionStorage.getItem('roc-floating-rotator-disable')) {
        div.appendChild(floating_rotator);
        setupFloatingData(reviews.data[floatingCounter], settings);
        if(floating_rotator.classList.contains('is-local')){
          if(floating_rotation_interval != null){
            clearInterval(floating_rotation_interval);
          }
          setupFloatingData(reviews.data[0], settings);
          floating_rotator.classList.add('visible');
        }else {
          floating_rotation_interval = setInterval(function () {
            if (floatingCounter >= reviews.data.length) {
              floatingCounter = 0;
            }
            setupFloatingData(reviews.data[floatingCounter], settings);
          }, (settings.layout_floating_rotator.delay * 1000) + 3000);
          floating_rotator.classList.add('visible');
        }
        floating_rotator.addEventListener('click', function (event) {
          if (event.target.classList.contains('roc-sess-close')) {
            if(!is_local) {
              sessionStorage.setItem('roc-floating-rotator-disable', true);
              clearInterval(floating_rotation_interval);
            }
            document.getElementById('roc-floating-rotator').classList.remove('visible');
            if(floating_rotator.classList.contains('is-local')) {
              setTimeout(()=>{
                document.getElementById('roc-floating-rotator').classList.add('visible');
              },1000)
            }
          }
        });
      }
      break;
    case 'floating':
      /*Button*/
      const floating_button = document.createElement('button');
      floating_button.setAttribute('class','roc-floating-btn js-roc-floating-panel-trigger');
      floating_button.setAttribute('style',`${settings.layout_floating.position}:10px; background:${settings.appearance_floating.badge.background_color}; color:${settings.appearance_floating.badge.text_color}; font-size:${settings.appearance_floating.badge.font_size}px; border-radius:${settings.appearance_floating.badge.border_radius}px`);
      if(settings.layout_floating.show_source) {
        const source_google = document.createElement('div');
        source_google.setAttribute('class', 'roc-floating-source');
        source_google.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" width="24" height="24"><defs><path id="A" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="B"><use xlink:href="#A"/></clipPath><g transform="matrix(.727273 0 0 .727273 -.954545 -1.45455)"><path d="M0 37V11l17 13z" clip-path="url(#B)" fill="#fbbc05"/><path d="M0 11l17 13 7-6.1L48 14V0H0z" clip-path="url(#B)" fill="#ea4335"/><path d="M0 37l30-23 7.9 1L48 0v48H0z" clip-path="url(#B)" fill="#34a853"/><path d="M48 48L17 24l-4-3 35-10z" clip-path="url(#B)" fill="#4285f4"/></g></svg>';
        floating_button.appendChild(source_google);
      }
      if(settings.layout_floating.show_badge_title) {
        const floating_title = document.createElement('div');
        floating_title.setAttribute('class', 'roc-floating-title');
        floating_title.innerText = settings.layout_floating.badge_title;
        floating_button.appendChild(floating_title);
      }
      const floating_rating_number = document.createElement('div');
      floating_rating_number.setAttribute('class','roc-floating-number')
      floating_rating_number.innerHTML = `${paginatedReviewItems.review_average.toFixed(1)}`;
      floating_button.appendChild(floating_rating_number);
      const floating_star_element = document.createElement('div');
      floating_star_element.setAttribute('class','roc-floating-stars');
      const floating_rating_width = (paginatedReviewItems.review_average / 5) * 100;
      floating_star_element.innerHTML = `<div class="rating-upper" style="width: ${floating_rating_width}%; color: ${settings.appearance_floating.badge.rating_color}"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div> <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>`;
      floating_button.appendChild(floating_star_element);
      if(settings.layout_floating.show_number_of_reviews){
        const read_reviews_link = document.createElement('span');
        read_reviews_link.setAttribute('class','floating-read-link');
        read_reviews_link.innerHTML = `Read our ${paginatedReviewItems.total} reviews`;
        floating_button.appendChild(read_reviews_link);
      }
      if(settings.layout_floating.show_close_button){
        /*Close Button*/
        console.log('close button')
        const remove_button = document.createElement('button');
        remove_button.setAttribute('class','js-roc-remove-close roc-floating-remove-elem');
        remove_button.innerHTML = `<svg style="width:15px;height:15px; color:${settings.appearance_review.text_color}" viewBox="0 0 24 24" class="roc-floating-remove-elem"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" class="roc-floating-remove-elem" /></svg>`;
        floating_button.appendChild(remove_button);
      }
      div.appendChild(floating_button);
      /*Panel*/
      const floating_element = document.createElement('div');
      floating_element.setAttribute('class',`roc-floating-panel roc-floating-panel--from-${settings.layout_floating.position} js-roc-floating-panel-main`);
      const floating_container = document.createElement('div');
      floating_container.setAttribute('class','roc-floating-panel__container');
      floating_container.setAttribute('style',`background:${settings.appearance_floating.panel.background_color}`)
      const floating_content = document.createElement('div');
      floating_content.setAttribute('class','roc-floating-panel__content');
      if(typeof title_element !== 'undefined'){
        floating_content.appendChild(title_element);
      }
      if(typeof caption_element !== 'undefined'){
        floating_content.appendChild(caption_element);
      }
      /*Close Button*/
      const close_button = document.createElement('button');
      close_button.setAttribute('class','roc-floating-panel__close js-roc-floating-close js-roc-floating-close-elem');
      close_button.innerHTML = `<svg class="js-roc-floating-close-elem" style="width:40px;height:40px; color:${settings.appearance_floating.panel.close_button_color}" viewBox="0 0 24 24"><path class="js-roc-floating-close-elem" fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" /></svg>`;
      floating_container.appendChild(close_button);

      const review_container = document.createElement('div');
      review_container.setAttribute('class','roc-floating-panel-reviews');
      review_container.setAttribute('style',`grid-template-columns: 1fr; gap: ${settings.layout_floating.gap}px;`)
      inner_widget = buildWidgetInner(review_container, reviewItems, layout_type, settings);
      floating_content.appendChild(inner_widget);

      floating_container.appendChild(floating_content);
      floating_element.appendChild(floating_container);
      div.appendChild(floating_element);
      /*scripts*/
      floating_button.addEventListener('click', function(event){
        event.preventDefault();
        floating_element.classList.add('roc-floating-panel--is-visible');
      });
      floating_element.addEventListener('click', function(event){
        if(event.target.classList.contains('js-roc-floating-close-elem') || event.target.classList.contains('js-roc-floating-panel-main')) {
          event.preventDefault();
          floating_element.classList.remove('roc-floating-panel--is-visible')
        }
      });
      break;
  }
  if(paginatedReviewItems.next_page_url && settings.type !== 'slider'){
    const load_more_container = document.createElement('div');
    load_more_container.setAttribute('class','roc-load-more-contain');
    const load_more_link = document.createElement('a');
    load_more_link.setAttribute('id','roc-load-more');
    load_more_link.setAttribute('href',paginatedReviewItems.next_page_url);
    load_more_link.setAttribute('style',`background:${settings.appearance_load_more.background_color}; color:${settings.appearance_load_more.text_color}; font-size:${settings.appearance_load_more.font_size}px; border-radius:${settings.appearance_load_more.border_radius}px`);
    load_more_link.innerText = "Load More";
    load_more_link.addEventListener("click", function(event){
      event.preventDefault();
      getWidget(event.target.href,settings).then((resp_settings) => {
        let current_div;
        reviews = resp_settings.reviews;
        switch(layout_type) {
          case 'grid':
            current_div = document.querySelector('.roc-grid');
            break;
          case 'slider':
            break;
          case 'masonry':
            current_div = document.querySelector('.roc-masonry-grid');
            break;
          case 'list':
            current_div = document.querySelector('.roc-list');
            break;
          case 'floating':
            current_div = document.querySelector('.roc-floating-panel-reviews');
            break
        }
        buildWidgetInner(current_div, resp_settings.reviews.data, settings.type, settings, true);
        load_more_link.setAttribute('href',resp_settings.reviews.next_page_url);
        if(!reviews.next_page_url){
          event.target.remove();
        }
      });
    });
    load_more_container.appendChild(load_more_link);
    if(settings.type === 'floating'){
      document.querySelector('.roc-floating-panel__content').appendChild(load_more_container);
    }else {
      div.appendChild(load_more_container);
    }
  }
};
let floatingCounter = 0;
const setupFloatingData = (review, settings) => {
  document.getElementById('roc-floating-rotator').classList.add('visible');
  if(settings.review_style.show_author_picture){
    document.getElementById('roc-floating-image').src = review.author_image;
  }
  if(settings.review_style.show_author_name) {
    document.getElementById('roc-floating-name').innerHTML = review.author_title;
  }
  if(settings.review_style.show_rating) {
    const rating_width = (review.review_rating / 5) * 100;
    document.getElementById('roc-floating-rating').setAttribute('style',`width: ${rating_width}%; color: ${settings.appearance_rating.color}`);
  }
  if(settings.review_style.show_date) {
    document.getElementById('roc-floating-date').innerHTML = review.days_ago;
  }
  if(settings.settings.enable_external_links) {
    document.getElementById('roc-floating-link').setAttribute('href', review.review_link);
  }
  const floating_text = document.getElementById('roc-floating-text');
  if(review.review_text){
    floating_text.innerHTML = `<p>${review.review_text}</p>`;
  }else{
    floating_text.innerHTML = `<p></p>`;
  }
  floatingCounter++;
  if(!document.getElementById('roc-floating-rotator').classList.contains('is-local')) {
    setTimeout(() => {
      document.getElementById('roc-floating-rotator').classList.remove('visible');
    }, (settings.layout_floating_rotator.delay * 1000));
  }
};
const setupReadMore = (settings,elem) => {
  if(settings.review_style.show_read_more_button && settings.type !== 'masonry') {
    const ps = elem.querySelector('p');
    ps.classList.add('trunc-enabled');
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        entry.target.classList[entry.target.scrollHeight > entry.contentRect.height ? 'add' : 'remove']('truncated');
      }
    });
    observer.observe(ps);
  }else{
    const ps = elem.querySelector('p');
    ps.classList.remove('trunc-enabled');
  }
}
window.roc_setup = async () => {
  if(!document.getElementById('g-preconnect1')){
    const preconnect1 = document.createElement('link');
    preconnect1.setAttribute('rel','reconnect');
    preconnect1.setAttribute('href','https://fonts.googleapis.com');
    preconnect1.setAttribute('id','g-preconnect1');
    document.head.appendChild(preconnect1);
  }
  if(!document.getElementById('g-preconnect2')){
    const preconnect2 = document.createElement('link');
    preconnect2.setAttribute('rel','reconnect');
    preconnect2.setAttribute('href','https://fonts.gstatic.com');
    preconnect2.setAttribute('corssorigin','');
    preconnect2.setAttribute('id','g-preconnect2');
    document.head.appendChild(preconnect2);
  }
  if(!document.getElementById('roc-styles')){
    const stylesheet = document.createElement('style');
    stylesheet.textContent = stylescss;
    stylesheet.setAttribute('id','roc-styles');
    document.head.appendChild(stylesheet)
  }
  const embeds = document.querySelectorAll("[class^=roc-app]");
  let widget_id = null;
  for(const embed of embeds){
    const css_class = embed.getAttribute('class');
    const correct_class = css_class.split(' ');
    if(!correct_class.length || !correct_class[0].startsWith('roc-app-')){
      console.log("proper roc class is missing");
    }else{
      widget_id = correct_class[0].replace('roc-app-','');
      if(widget_id != null && widget_id !== 'undefined') {
        let url = `${BASE_URL}/get-widget/${widget_id}`;
        switch (widget_id) {
          case "example-slider":
            url = `${BASE_URL}/example-widget/slider`;
            break;
          case "example-floating":
            url = `${BASE_URL}/example-widget/floating`;
            break;
        }
        await getWidget(url).then((resp_settings) => {
          if (resp_settings.settings.font.backend_name !== 'system' && !document.getElementById(resp_settings.settings.font.frontend_label)) {
            const chosen_font = document.createElement('link');
            chosen_font.setAttribute('rel', 'stylesheet');
            chosen_font.setAttribute('href', resp_settings.settings.font.href);
            chosen_font.setAttribute('id', resp_settings.settings.font.frontend_label);
            document.head.appendChild(chosen_font);
          }
          if (widget_id.includes("example-")) {
            reviews = window.roc_reviews;
          } else {
            reviews = resp_settings.reviews;
          }
          buildWidget(embed, reviews, resp_settings.type, resp_settings);
          const schema_script = document.getElementById('roc-schema');
          if (resp_settings.settings.enable_schema) {
            if (schema_script === null) {
              const schema_script = document.createElement('script');
              schema_script.setAttribute('type', 'application/ld+json');
              schema_script.setAttribute('id', 'roc-schema');
              schema_script.innerHTML = JSON.stringify(resp_settings.schema);
              document.body.appendChild(schema_script);
            }
          } else if (schema_script !== null) {
            schema_script.remove();
          }
          if (window.origin.includes('reviewourchurch')) {
            setupPreviewEvents(embed);
          }
        });
      }
    }
  }
}
window.roc_setup();
